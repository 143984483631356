
.dashboard-home{
    display: flex;
    flex-direction: column;
    gap:16px;
    color:rgb(73,80,87)
}
.dashboard-intro{
    border-radius: 20px;
   
}
.greetings-div{
    display: flex;
   
    justify-content: space-between;
    background-color: rgb(212,219,249);
    padding: 16px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

}

.greetings-div .left{
    width:40%;
}
.greetings-div .right{
    width:60%;
    display: flex;
    justify-content: right;
}

.intro-down{
    background-color: white;
    padding: 16px;
    padding: 16px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.account-label{
font-size: 40px;
font-weight: bold;
display: flex;
align-items: center;
gap: 2px;

}


.paymentOptions{
    display: flex;
    gap:15px;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    padding: 16px;
}
.paymentOptions>div{
   
   background-color: white;
   padding: 10px;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   text-align: center;
   align-items: center;
   gap:10px;


}
.paymentOptions>div>img{
   width: 50px;
   height: 50px;
}
.dashboard-details{
    display: flex;
    flex-direction: column;
    gap:16px;
}

.dashboard-card-wrapper{
    display: flex;
    flex-wrap: wrap;  
}
.dashboard-card-wrapper>div{
   width: calc(100% / 2);
   margin-bottom: 16px;
   padding :0 16px;
}

.recent-transactions{
    background-color: white;
    padding:16px;
    border-radius: 16px;
}
.recent-transactions>h3{
    font-weight: bold;
}

.currency-denomination{
    font-size: 10px;
}
.dashboardTabLoading{
    font-size: 60px;
    margin: 0 auto;
        color:#80c2e9; ;
}
.empty-balance{
    display: flex;
    justify-content: center;
    align-items: center;
}
.empty-balance>h2{
    font-size: 20px;
}


@media screen and (max-width: 768px) {
  

    .greetings-div{
        display: flex;
        justify-content: space-between;
        background-color: rgb(212,219,249);
        padding: 10px;
       
    }
    .greetings-div>.left{
        display: flex;

        
        background-color: rgb(212,219,249);
        padding: 10px;
        width: 90%;
        gap:5px;
        font-size: 14px;
       
    }
    .greetings-div>.left>h1{
       padding: 0px;
       font-size: 14px;
       
    }
    .greetings-div>.left>p{
       padding: 0px;
       font-size: 14px;
       
    }

    .paymentOptions{
        display: flex;
        gap:15px;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
      
        font-size: 10px;
    }
    .paymentOptions>div{
       
       background-color: white;
       padding: 10px;
       width: calc(100% /5);
       border-radius: 10px;
       display: flex;
       flex-direction: column;
       justify-content: center;
       text-align: center;
       align-items: center;
       gap:5px;
    
    
    }
    .paymentOptions>div>img{
       width: 20px;
       height: 20px;
    }
    .account-label{
        font-size: 15px;
       
        
        }
 
  
}