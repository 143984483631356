/* Navbar.css */

.navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--red-700);
    color: black;
    padding: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.18)
}

.nav-container {
    width: 90%;
    display: flex;
    align-items: center;
    ;
    justify-content: space-between;
}
.logo-part{

   
   display: flex;
   align-items: center;
   gap:10px;
   justify-content: center;
   
}

.logoImage{
    width: 50px;
    height: 50px;
   
}
.logo {
    display: flex;
   gap:5px;
}
.logo>p {
    padding: 0;
    font-size: 25px;
    font-weight: bold;
}

.logored{
    color:var(--red-200)
}
.logowhite{
    color:var(--red-900)
}

.logo>span {
    color:white;
}

.nav-container>ul {
    list-style-type: none;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
    text-decoration: none;
   
}

.nav-links {
    display: flex;
    gap: 1rem;
    align-items: center;
}


.nav-links li>a {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: black;
    text-decoration: none;
}

.nav-button {
   
   padding: 5px;
   width: 100px;
   border-radius: 10px;
   text-align: center;
}



.menu-icon {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
}
.nav-button-wrapper{
    display: flex;
    gap: 10px;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .nav-bar {
        background-color: var(--red-200);
    }

    .nav-container>ul>li>a{
        
        text-decoration: none;
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -1px;
        text-align: left;

    }

    .logo>p {
        padding: 0;
        font-size: 20px;
        font-weight: bold;
    }

    .nav-links {
        display: none;
    }


    .nav-links-mobile {
        display: flex;
        flex-direction: column;
        position: absolute;
        gap:1.3rem;
        top: 82px;
        left: 0;
        width: 100%;
        z-index: 1;
        padding: 30px;
       
        background-color: var(--red-500)
        ;
    }

    .nav-button {
        margin-left: 0px;
    }

    .menu-icon {
        display: block;
    }

    .logo {
    
        font-size: 30px;
        font-weight: 700;
        line-height: 44px;
        letter-spacing: 0em;
        text-align: left;
    }
    .logoImage{
        width: 30px;
        height: 30px;
       
    }
}